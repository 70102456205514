$font-family-sans-serif: // Safari for OS X and iOS (San Francisco)
        -apple-system,
          // Chrome < 56 for OS X (San Francisco)
        BlinkMacSystemFont,
          // Windows
        "Segoe UI",
          // Android
        "Roboto",
          // Basic web fallback
        "Helvetica Neue", Arial, sans-serif,
          // Emoji fonts
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

body {
  font-size: 14px;
  font-family: $font-family-sans-serif;
  max-width: 1000px;
  margin: 0 auto 1000px;
  padding-left: 20px;
  padding-right: 20px;
  color: rgb(80, 80, 80);
}

.column {
  @media screen and (min-width: 800px) {
    width: 45%;
    &:first-child {
      float: left;
    }
    &:last-child {
      float: right;
    }
  }
}

.example-details {
  margin-bottom: 10px;
}
.select-row {
  margin-bottom: 20px;
}

@import "dd";
