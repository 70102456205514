$border-color: #d3d9de;
$open-icon-width: 20px;

.dd {
  border: 1px solid $border-color;
  padding: 2px 4px;
  border-radius: 2px;
  position: relative;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  &__input {
    border: none;
    outline: none;
    width: calc(100% - #{$open-icon-width});
    box-sizing: border-box;
    font-size: 14px;
    height: 25px;
    padding: 2px 10px;
    margin: 0;
    color: rgb(119, 119, 119);
    &[readonly] {
      user-select: none;
      cursor: default;
    }
  }
  &__selection {
    width: calc(100% - #{$open-icon-width});
    box-sizing: border-box;
    font-size: 14px;
    line-height: 21px;
    height: 25px;
    padding: 2px 10px;
    margin: 0;
    position: relative;
    &__cancel {
      position: absolute;
      cursor: pointer;
      right: 3px;
      top: 1px;
      width: 20px;
      text-align: center;
      display: block;
      opacity: .5;
      font-style: normal;
      transform: scale(1.7);
      &:hover {
        opacity: 1;
      }
    }
  }
  &__label {
    float: left;
    font-size: 1em;
    line-height: 1;
    display: inline-block;
    padding: 5px 10px;
    background: #5181b8;
    margin: 1px 2px 2px 0;
    border-radius: 2px;
    color: #fff;
    user-select: none;
    &__cancel {
      margin-left: 6px;
      display: inline-block;
      transform: scale(1.5) translateY(-1px);
      cursor: pointer;
      font-style: normal;
      &:hover {
        opacity: .8;
      }
    }
    &__plus {
      margin-left: 2px;
      display: inline-block;
      font-style: normal;
      transform: scale(1.4) translateY(-1px);
      &:hover {
        opacity: .8;
      }
    }
    &--add {
      cursor: pointer;
      background: #dae2ea;
      color: #55677d;
    }
  }
  &__open-icon {
    float: right;
    height: 27px;
    width: $open-icon-width;
    cursor: pointer;
    position: relative;
    &:after {
      position: absolute;
      right: 8px;
      top: 12px;
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: .255em;
      vertical-align: .255em;
      content: '';
      border-top: .3em solid;
      border-right: .3em solid transparent;
      border-bottom: 0;
      border-left: .3em solid transparent;
    }
  }
}

.dd-menu-wrapper {
  position: relative;
}

.dd-menu {
  position: absolute;
  z-index: 30;
  left: 0;
  right: 0;
  width: calc(100% - 2px);
  background: white;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  border-radius: 0 0 2px 2px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  &--not-found {
    max-height: none;
    overflow-y: hidden;
  }
  &--hidden {
    transform: translateX(-9999px);
  }
  &__item {
    user-select: none;
    padding: 8px 14px;
    display: flex;
    align-items: center;
    &--highlighted {
      background: #f0f2f5;
    }
    &--not-found {
      cursor: default;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    &--hidden {
      display: none;
    }
    &--lazy {
      position: absolute;
      left: 0;
      right: 0;
    }
  }
  &__img {
    display: block;
    margin-right: 10px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    max-width: 30px;
    max-height: 30px;
  }
}
